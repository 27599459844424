<template>
  <div>
    <div id="pageServer" class="mainContents">
      <section class="titleArea">
        <h2>404 Not Found</h2>
        <!-- /.titleArea -->
      </section>

      <div class="serverArea">
        <div class="inner">
          <p class="message">
            申し訳ありません。<br>
            お探しのページが<br class="sp">見つかりませんでした。
          </p>
          <div class="buttonArea">
            <p>
              <router-link :to="{ name: 'home' }">
                トップページへ
              </router-link>
            </p>
            <!-- /.buttonArea -->
          </div>
        </div>
        <!-- /.registProfileArea -->
      </div>

      <!-- /.mainContents -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    resource: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped></style>
